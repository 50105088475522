import { makeStyles, Radio } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    margin: '0',
    overflowY: 'unset',
  },
  container: {
    display: 'flex',
    width: '600px',
    height: 'auto',
    padding: '12px',
  },
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'black !important',
  },
  label: {
    fontSize: '14px',
  },
  shifts: {
    borderRight: '1px solid black',
    marginRight: '8px',
  },
  testdrives: {
    borderRight: '1px solid black',
    marginRight: '8px',
    padding: '0 12px',
  },
  weekOff: {
    padding: '0 12px',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },
}));

const StyledRadio = (props) => {
  const classes = useStyles();
  return (
    <Radio
      // className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default StyledRadio;
