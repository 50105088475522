import calendar from '../../constants/dataset/calendar';

export const isInteger = (val) => /^-{0,1}\d+$/.test(val);

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseData = (obj, index) => {
  if (!index || Array.isArray(index)) return null;

  return index.split('.').reduce((data, prop) => {
    if (isInteger(prop) && data && Array.isArray(data)) {
      let num = parseInt(prop);
      if (Math.abs(num) >= data.length) {
        num %= data.length;
      }
      if (num >= 0) return data[num];
      else return data[data.length + num];
    }
    if (data && isJson(data)) {
      data = JSON.parse(data);
      return data[prop];
    }
    return data && data[prop] !== null ? data[prop] : null;
  }, obj);
};


export const parseConditional = (data, info) => {
  const { iff, elsee } = info;
  if(parseData(data, iff.variable) == iff.value) {
    return returnConditional(iff.return, data);
  }
  else {
    return returnConditional(elsee.return, data);
  }
}

export const returnConditional = ({type, value}, data) => {
  switch (type) {
    case 'text': 
      return value;
    case 'calendarShifts' : 
      if(value.dataset) {
        const shift = data[value.dataset][parseData(data, value.dataIndex)].display_name.split(' ')[0];
        return calendar[value.dataset][shift] || shift;
      }
      return value;
    case 'icon' : 
      return value;
  }
}
