import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './VirtualizedScrollSyncGridWrapper.module.scss';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import { ScrollSync, Grid, AutoSizer, ColumnSizer } from 'react-virtualized';
import { formatData } from '../../helpers/formatters/formatData';
import Pagination from '@material-ui/lab/Pagination';
import { formatElements } from '../../helpers/formatters/formatElements';
import componenthelper from '../../helpers/componentHelper';
import './CustomVirtualizedScrollSyncGridWrapper.scss';

const NO_OF_LEFT_FIXED_COLUMNS = 0;
const NO_OF_RIGHT_FIXED_COLUMNS = 0;
const RECORDS_IN_ONE_PAGE = 10;

function overscanIndicesGetter({
  direction, // One of "horizontal" or "vertical"
  cellCount, // Number of rows or columns in the current axis
  scrollDirection, // 1 (forwards) or -1 (backwards)
  overscanCellsCount, // Maximum number of cells to over-render in either direction
  startIndex, // Begin of range of visible cells
  stopIndex, // End of range of visible cells
}) {
  return {
    overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
    overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
  };
}

const VirtualizedScrollSyncGridWrapper = ({
  columnWidth = 600,
  height = 400,
  overscanColumnCount = 100,
  overscanRowCount = 5,
  rowHeight = 50,
  columns,
  tableData: data,
  requestData,
  setInformation,
  setInformationSuccess,
  editableRow,
  totalCount,
  setRowEditInformation,
  dataObjects,
  shifts,
  expandedRow,
  expandedRowComponent,
  setRowExpandedInformation,
  expandedRowState,
}) => {
  const columnCount = columns.length || 0;
  const [rowCount, updateRowCount] = React.useState(RECORDS_IN_ONE_PAGE);
  React.useEffect(() => {
    if (![null, undefined].includes(expandedRow))
      updateRowCount(RECORDS_IN_ONE_PAGE + 1);
    else updateRowCount(RECORDS_IN_ONE_PAGE);
  }, [expandedRow]);

  const _renderTopLeftHeaderCell = ({ columnIndex, key, style }) => {
    return (
      <div
        className={clsx(
          styles.container__table__topLeftFixedGridContainer__grid__cell,
          styles.genericCell
        )}
        key={key}
        style={style}
      >
        {columns[columnIndex].title}
      </div>
    );
  };

  const _renderMiddleHeaderCell = ({ columnIndex, key, rowIndex, style }) => {
    if (NO_OF_LEFT_FIXED_COLUMNS > 0 && columnIndex < 1) {
      return;
    }
    return (
      <div
        className={clsx(
          styles.container__table__middleGridContainer__header__grid__cell,
          styles.genericCell
        )}
        key={key}
        style={style}
      >
        {columns[columnIndex].title}
      </div>
    );
  };

  const _renderCellData = (
    rowIndex,
    columnIndex,
    row,
    column,
    computedRowIndex
  ) => {
    let cellData;
    if (column.customElements) {
      if (Array.isArray(column.customElements)) {
        cellData = (
          <>
            {column.customElements.reduce((acc, ite) => {
              return (
                <>
                  {acc}
                  {formatElements({
                    info: ite,
                    refData: {
                      currentRow: computedRowIndex,
                      currentColumn: columnIndex,
                      ...dataObjects[row.id],
                      editableRow,
                      expandedRow,
                      expandedRowState,
                    },
                    functions: {
                      setTableInformation: (information) =>
                        setInformation(information),
                      setInformationSuccess: (information) => 
                        setInformationSuccess(information),
                      setRowEditInformation: (information) =>
                        setRowEditInformation({ information, rowKey: row.id }),
                      setRowExpandedInformation: (information) =>
                        setRowExpandedInformation({
                          information,
                          rowKey: row.id,
                        }),
                      requestTableData: requestData,
                    },
                  })}
                </>
              );
            }, null)}
          </>
        );
      } else {
        cellData = formatElements({
          info: column.customElements,
          refData: {
            currentRow: computedRowIndex,
            currentColumn: columnIndex,
            ...dataObjects[row.id],
            shifts,
            editableRow,
            expandedRow,
            expandedRowState,
          },
          functions: {
            setTableInformation: (information) => setInformation(information),
            setInformationSuccess: (information) => setInformationSuccess(information),
            setRowEditInformation: (information) =>
              setRowEditInformation({ information, rowKey: row.id }),
            setRowExpandedInformation: (information) =>
              setRowExpandedInformation({
                information,
                rowKey: row.id,
              }),
            requestTableData: requestData,
          },
        });
      }
    } else {
      cellData = formatData({
        data: row,
        info: column.customFormat || {
          type: column.type || 'text',
          dataIndex: column.dataIndex,
        },
      });
      if (typeof cellData === 'string' && cellData.trim().length === 0) {
        return '--';
      }
    }

    return cellData || '--';
  };

  const _renderBodyCell = ({
    columnIndex,
    key,
    rowIndex,
    style,
    expandedRow,
    width,
  }) => {
    if (NO_OF_LEFT_FIXED_COLUMNS > 0 && columnIndex < 1) {
      return;
    }
    const rowClass = rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow;
    const computedRowIndex =
      expandedRow !== null && Number(rowIndex) > Number(expandedRow)
        ? Number(rowIndex) - 1
        : rowIndex;
    if (computedRowIndex >= data.length) return;
    const column =
      editableRow === computedRowIndex && columns[columnIndex].edit
        ? columns[columnIndex].edit
        : columns[columnIndex];
    const row = data[computedRowIndex];
    return (
      <div
        className={clsx(
          rowClass,
          styles.container__table__middleGridContainer__grid__cell,
          styles.genericCell
        )}
        key={key}
        style={{
          ...style,
          ...(expandedRow !== null && {
            width:
              Number(rowIndex) === Number(expandedRow) + 1 && columnIndex === 0
                ? width
                : style.width,
            height:
              Number(rowIndex) === Number(expandedRow) + 1 && columnIndex === 0
                ? 95
                : style.height,
            zIndex:
              Number(rowIndex) === Number(expandedRow) + 1 && columnIndex === 0
                ? 1
                : null,
            display:
              Number(rowIndex) !== Number(expandedRow) + 1 || columnIndex === 0
                ? 'flex'
                : 'none',
            top:
              Number(rowIndex) > Number(expandedRow) + 1
                ? style.top + 45
                : style.top,
          }),
        }}
      >
        {expandedRow !== null &&
        Number(rowIndex) === Number(expandedRow) + 1 &&
        columnIndex === 0 ? (
          <>
            {componenthelper({
              component: {
                onChange: (information) =>
                  setRowExpandedInformation({ information, rowKey: row.id }),
                data: row,
                ...expandedRowComponent,
              },
            })}
          </>
        ) : !expandedRow || Number(rowIndex) !== Number(expandedRow) + 1 ? (
          _renderCellData(rowIndex, columnIndex, row, column, computedRowIndex)
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <ScrollSync>
        {({ onScroll, scrollLeft, scrollTop }) => {
          return (
            <div className={styles.container__table}>
              <div
                className={styles.container__table__topLeftFixedGridContainer}
              >
                <Grid
                  cellRenderer={_renderTopLeftHeaderCell}
                  className={styles.HeaderGrid}
                  width={NO_OF_LEFT_FIXED_COLUMNS * columnWidth}
                  height={rowHeight}
                  rowHeight={rowHeight}
                  columnWidth={columnWidth}
                  rowCount={1}
                  columnCount={NO_OF_LEFT_FIXED_COLUMNS}
                  autoWidth
                />
              </div>
              <div
                className={styles.container__table__leftFixedGridContainer}
                style={{
                  top: rowHeight,
                }}
              >
                <Grid
                  overscanColumnCount={overscanColumnCount}
                  overscanRowCount={overscanRowCount}
                  cellRenderer={(props) =>
                    _renderLeftSideCell({ ...props, expandedRow })
                  }
                  columnWidth={columnWidth}
                  columnCount={NO_OF_LEFT_FIXED_COLUMNS}
                  className={styles.LeftSideGrid}
                  height={height - scrollbarSize()}
                  rowHeight={rowHeight}
                  rowCount={rowCount}
                  scrollTop={scrollTop}
                  width={NO_OF_LEFT_FIXED_COLUMNS * columnWidth}
                />
              </div>
              <div className={styles.container__table__middleGridContainer}>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <>
                      <div
                        style={{
                          height: rowHeight,
                          width: width - scrollbarSize(),
                        }}
                        className={
                          styles.container__table__middleGridContainer__header
                        }
                      >
                        <ColumnSizer
                          columnMaxWidth={1000}
                          columnMinWidth={50}
                          columnCount={columnCount}
                          width={width}
                        >
                          {({ getColumnWidth, registerChild }) => (
                            <Grid
                              className={styles.HeaderGrid}
                              columnWidth={getColumnWidth}
                              columnCount={columnCount}
                              height={rowHeight}
                              overscanColumnCount={overscanColumnCount}
                              cellRenderer={_renderMiddleHeaderCell}
                              rowHeight={rowHeight}
                              rowCount={1}
                              scrollLeft={scrollLeft}
                              width={width - scrollbarSize()}
                              ref={registerChild}
                            />
                          )}
                        </ColumnSizer>
                      </div>
                      <div
                        className={
                          styles.container__table__middleGridContainer__grid
                        }
                      >
                        <ColumnSizer
                          columnMaxWidth={1000}
                          columnMinWidth={50}
                          columnCount={columnCount}
                          width={width}
                        >
                          {({ getColumnWidth, registerChild }) => (
                            <Grid
                              className={styles.BodyGrid}
                              columnWidth={getColumnWidth}
                              columnCount={columnCount}
                              height={
                                expandedRow
                                  ? rowHeight * rowCount + 45
                                  : rowHeight * rowCount
                              }
                              onScroll={onScroll}
                              overscanColumnCount={overscanColumnCount}
                              overscanRowCount={overscanRowCount}
                              cellRenderer={(props) =>
                                _renderBodyCell({
                                  ...props,
                                  expandedRow,
                                  width,
                                })
                              }
                              rowHeight={rowHeight}
                              rowCount={rowCount}
                              width={width}
                              overscanIndicesGetter={overscanIndicesGetter}
                              ref={registerChild}
                            />
                          )}
                        </ColumnSizer>
                      </div>
                    </>
                  )}
                </AutoSizer>
              </div>
              <div
                className={styles.container__table__topRightFixedGridContainer}
                style={{
                  position: 'absolute',
                  right: scrollbarSize(),
                  top: 0,
                }}
              >
                <Grid
                  cellRenderer={(cellProps) =>
                    _renderRightHeaderCell({
                      ...cellProps,
                      NO_OF_RIGHT_FIXED_COLUMNS,
                      columnCount,
                    })
                  }
                  className={
                    styles.container__table__topRightFixedGridContainer__grid
                  }
                  width={NO_OF_RIGHT_FIXED_COLUMNS * columnWidth}
                  height={rowHeight}
                  rowHeight={rowHeight}
                  columnWidth={columnWidth}
                  rowCount={1}
                  columnCount={NO_OF_RIGHT_FIXED_COLUMNS}
                />
              </div>
              <div
                className={styles.container__table__rightFixedGridContainer}
                style={{
                  position: 'absolute',
                  right: scrollbarSize(),
                  top: rowHeight,
                }}
              >
                <Grid
                  overscanColumnCount={overscanColumnCount}
                  overscanRowCount={overscanRowCount}
                  cellRenderer={(props) =>
                    _renderRightFixedCells({ ...props, expandedRow })
                  }
                  columnWidth={columnWidth}
                  columnCount={NO_OF_RIGHT_FIXED_COLUMNS}
                  className={
                    styles.container__table__rightFixedGridContainer__grid
                  }
                  height={height - scrollbarSize()}
                  rowHeight={rowHeight}
                  rowCount={rowCount}
                  scrollTop={scrollTop}
                  width={NO_OF_RIGHT_FIXED_COLUMNS * columnWidth}
                />
              </div>
            </div>
          );
        }}
      </ScrollSync>
      <Pagination
        count={Math.ceil(totalCount / RECORDS_IN_ONE_PAGE)}
        onChange={(e, page) =>
          setInformation({
            page,
            editableRow: null,
            expandedRow: null,
          })
        }
      />
    </div>
  );
};

VirtualizedScrollSyncGridWrapper.propTypes = {
  columnWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  overscanColumnCount: PropTypes.number.isRequired,
  overscanRowCount: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  columns: PropTypes.object.isRequired,
  tableData: PropTypes.object.isRequired,
  requestData: PropTypes.object.isRequired,
  dataObjects: PropTypes.object.isRequired,
  editableRow: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  expandedRow: PropTypes.number.isRequired,
  expandedRowComponent: PropTypes.object.isRequired,
  setInformation: PropTypes.func.isRequired,
  setInformationSuccess: PropTypes.func.isRequired,
  setRowEditInformation: PropTypes.func.isRequired,
  // expandedRow: PropTypes.number.isRequired,
  // width: PropTypes.number.isRequired,
};

const _renderLeftHeaderCell = ({ columnIndex, key, style }) => {
  return (
    <div className={styles.headerCell} key={key} style={style}>
      {`C${columnIndex}`}
    </div>
  );
};

_renderLeftHeaderCell.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};

const _renderRightHeaderCell = ({
  columnIndex,
  key,
  style,
  NO_OF_RIGHT_FIXED_COLUMNS,
  columnCount,
}) => {
  return (
    <div
      className={clsx(
        styles.container__table__topRightFixedGridContainer__grid__cell,
        styles.genericCell
      )}
      key={key}
      style={style}
    >
      {`C${columnCount - NO_OF_RIGHT_FIXED_COLUMNS + columnIndex}`}
    </div>
  );
};

_renderRightHeaderCell.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  NO_OF_RIGHT_FIXED_COLUMNS: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  columnCount: PropTypes.string.isRequired,
};

const _renderLeftSideCell = ({
  columnIndex,
  key,
  rowIndex,
  style,
  expandedRow,
}) => {
  const rowClass = rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow;
  const classNames = clsx(rowClass, styles.genericCell);

  return (
    <div className={classNames} key={key} style={style}>
      {`R${
        expandedRow !== null && Number(rowIndex) > Number(expandedRow)
          ? Number(rowIndex) - 1
          : rowIndex
      }, C${columnIndex}`}
    </div>
  );
};

_renderLeftSideCell.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  rowIndex: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  expandedRow: PropTypes.string.isRequired,
};

const _renderRightFixedCells = ({
  columnIndex,
  key,
  rowIndex,
  style,
  expandedRow,
}) => {
  const rowClass = rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow;
  return (
    <div
      className={clsx(
        styles.container__table__rightFixedGridContainer__grid__cell,
        styles.genericCell,
        rowClass
      )}
      key={key}
      style={style}
    >
      {`R${
        expandedRow !== null && Number(rowIndex) > Number(expandedRow)
          ? Number(rowIndex) - 1
          : rowIndex
      }, C${columnIndex}`}
    </div>
  );
};

_renderRightFixedCells.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  rowIndex: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  expandedRow: PropTypes.string.isRequired,
};

export default VirtualizedScrollSyncGridWrapper;
