import { Tooltip, withStyles } from '@material-ui/core';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 5px 15px 0px rgba(0,0,0,0.3)',
    fontSize: 14,
  },
}))(Tooltip);

export default LightTooltip;
