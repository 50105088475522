export const weekDays = new Array(
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
);


 const shifts = {
  'Early': 'Early',
  'Morning': 'Morn',
  "Afternoon": "Noon",
  "Global": 'Glob',
};


export default  {shifts, weekDays};