import React from 'react';

const Home = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 8 8">
    <g fill="none" fillRule="evenodd">
        <g fill="#6300A3" fillRule="nonzero">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path d="M16.895 56.128l-3.79-3.056c-.126-.102-.315-.093-.431.01l-3.58 3.056c-.062.046-.094.12-.094.194v4.39c0 .158.137.278.316.278h2.631c.18 0 .316-.12.316-.278v-2.037h1.474v2.037c0 .158.137.278.316.278h2.631c.18 0 .316-.12.316-.278v-4.39c0-.074-.042-.148-.105-.204z" transform="translate(-179 -594) translate(64 68) translate(13 415) translate(0 58) translate(93)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default Home;