import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import OptionsDispatcher from '../../redux/options/actions';
import TableDispatcher from '../../redux/table/actions';
import { formatData, formatOptions } from '../../helpers/formatters/formatData';
import { generateDataset } from '../../helpers/formatters/generateData';

export default (props) => {
  const {
    element: {
      label = 'Select',
      constant,
      multiple = false,
      customOptions,
      defaultValueDataIndex,
      formatValue,
      onSelect,
      getData,
      changeData,
      optionsType = "",
    },
    elementKey,
    value = multiple ? [] : '',
    refData,
  } = props || {};

  let {
    // eslint-disable-next-line react/prop-types
    functions
  } = props;
  const [selectValue, updateSelectValue] = useState(value);
  const [options, updateOptions] = useState([]);
  const dispatch = useDispatch();
  const optionsDispatcher = new OptionsDispatcher(dispatch);
  const tableDispatcher = new TableDispatcher(dispatch);

  const { optionsFetched= [], selectedCategory, selectedCity, selectedTL } = useSelector((state) => ({
    optionsFetched: state.options[elementKey],
    selectedCategory: state.table.selectedCategory,
    selectedCity: state.table?.selectedCity,
    selectedTL: state.table?.selectedTL,
  }), [shallowEqual]);

  const functionsDeclared = {
      onSelectHub: (val)=>{
        tableDispatcher.getTableData({
              ...changeData,
              key: 'hubManagerAgents',
              hub_id: val?.hubs || 1
          })
      },
      onSelectCategory: (val)=>{
        tableDispatcher.getTableData({
              ...changeData,
              key: 'insideSalesAdmin',
              selectedCategory: val?.category,
              selectedCity:selectedCity,
              selectedTL:selectedTL
          })
      },
      onSelectCity: (val)=>{
        tableDispatcher.getTableData({
              ...changeData,
              key: 'insideSalesAdmin',
              selectedCity: val?.cities,
              selectedCategory: selectedCategory,
              selectedTL:selectedTL
          })
      },
  }

  functions = {...functions, ...functionsDeclared}


  const requestData = () => {
    optionsDispatcher.getOptions({
      key: elementKey,
      ...getData,
    });
    return optionsFetched;
  };

  const _updateValue = (value) => {
    functions[onSelect.functionToCall]({
      [elementKey]: formatValue
        ? formatData({
            data: value,
            info: {
              ...formatValue,
              allOptions: options.map((option) => option.key),
            },
          })
        : value,
    });
    updateSelectValue(value);
  };

  useEffect(() => {
    if (defaultValueDataIndex) {
      updateSelectValue(
        formatData({
          data: refData,
          info:
            typeof defaultValueDataIndex === 'object'
              ? defaultValueDataIndex
              : { type: 'text', dataIndex: defaultValueDataIndex },
        })
      );
    }
    let generatedOptions = [];
    if (constant) generatedOptions = constant;
    else if (customOptions && optionsType === '') {
      generatedOptions = generateDataset( customOptions, refData );
    }
    else if(customOptions && optionsType === 'fetch') {
      requestData();
    }
    updateOptions(generatedOptions);
  }, []);
  
  // this useeffect will only run in case of if elementKey is category
  useEffect(()=>{
    if (selectValue && elementKey === 'category' && !multiple && options?.[0]?.key && !selectedTL && !selectedCategory && !selectedCity) {
      _updateValue(options[0].key);
    }
  },[selectValue]);

  useEffect(()=>{
    if (optionsType === "fetch") {
      updateOptions(formatOptions(customOptions,optionsFetched));
    }
  },[optionsFetched]);

  useEffect(()=>{
    if (optionsType === "fetch" && options && options?.length !==0 && (elementKey === 'hubs' || elementKey === 'category')) {
      updateSelectValue(options?.[0]?.key);
    }
  }, [options])

  const _handleChange = (event) => {
    _updateValue(event.target.value);
  };

  const _generateOptions = () => {
    return options && options.map((option) => (
      <MenuItem key={option.key} value={option.key}>
        {option.displayName}
      </MenuItem>
    ));
  };

  return (
    <FormControl style={{ width: 160 }}>
      <InputLabel id={`${elementKey}-label`}>{label}</InputLabel>
      <Select
        labelId={`${elementKey}-label`}
        multiple={!!multiple}
        value={selectValue}
        onChange={_handleChange}
        input={<Input />}
      >
        {_generateOptions()}
      </Select>
    </FormControl>
  );
};
