import { logRoles } from '@testing-library/react';
import React, { useEffect } from 'react';
import componentHelper from '../../helpers/componentHelper';
import conditionHelper from '../../helpers/conditionHelper';
import './DynamicGrid.css';

export default ({ elements, style }) => {
  return (
    <div className="container" style={style}>
      {React.Children.toArray(
      (elements || []).map((elementObj) =>
        conditionHelper(elementObj.condition) ? (
          <div style={elementObj.style} className="border-primary">
            {componentHelper({ component: elementObj.component })}
          </div>
        ) : null
      ))}
    </div>
  );
};
