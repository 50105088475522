import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  RadioGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import StyledRadio from '../../../../../StyledRadio';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'black !important',
  },
  label: {
    fontSize: '14px',
  },
  shifts: {
    borderRight: '1px solid black',
    marginRight: '8px',
  },
}));

const ShiftForm = ({ date, dayInfo, onChange, shifts }) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.shifts}>
      <FormLabel
        classes={{ root: classes.root }}
        component="legend"
      >{`Shift on ${date}`}</FormLabel>
      <RadioGroup
        aria-label="shift"
        name="shift"
        value={dayInfo.shift}
        // onChange={handleShift}
        onChange={(e) => onChange({ shift: Number(e.target.value) })}
      >
        {Object.keys(shifts).length ? 
        (React.Children.toArray(Object.values(shifts).map(shift=> {
          const {id, display_name, start_time, end_time} = shift
          return (
          <FormControlLabel
          classes={{ label: classes.label }}
          value={id}
          control={<StyledRadio />}
          label={`${display_name} (${start_time} - ${end_time})`}
        />)
        }))):
        (<>
           <FormControlLabel
          classes={{ label: classes.label }}
          value={1}
          control={<StyledRadio />}
          label="Early Shift (8:00 - 16:00)"
        />
        <FormControlLabel
          classes={{ label: classes.label }}
          value={2}
          control={<StyledRadio />}
          label="Mid Shift (10:00 - 18:00)"
        />
        <FormControlLabel
          classes={{ label: classes.label }}
          value={3}
          control={<StyledRadio />}
          label="Late Shift (12:00 - 20:00)"
        /> 
        </>
        )
}
      </RadioGroup>
    </FormControl>
  );
};

ShiftForm.propTypes = {
  classes: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  dayInfo: PropTypes.object,
};
export default ShiftForm;
