import { combineEpics, ofType } from 'redux-observable';
import { mergeMap, map, takeUntil, catchError, switchMap } from 'rxjs/operators';
import { of,forkJoin  } from 'rxjs';
import { getData, normaliseData } from '../../utils/table';
import actions from './constants';

const getBatchEpic = (getterFunc, initial, success, cancel, failure) => (action$) =>
  action$.pipe(
    ofType(initial),
    switchMap((action) => {
        //call apis in parallel
        const reqInfo = {...action.payload};
        delete reqInfo['REST'];
        const parallelObservables = action.payload.REST
          .map(rest => getterFunc('GET', {REST: {...rest}, ...reqInfo})
              .pipe(
                  mergeMap(response => of(response)),
                  catchError(err => of(null))
          ));           
        //forkJoin works like Promise.all()
        return forkJoin(parallelObservables,
            (...args) => { //projection
                // const responses = {}
                const responses = {}
                const dataObj = ['tableData', 'shifts']
                args.forEach((arg, idx)=> {
                  responses[dataObj[idx]] = arg.response || {}
                })
                return responses;
            })
            .pipe(mergeMap(response => {
              const {count, results} = response['tableData'] || {};
              const {results: shifts} = response['shifts'] || {};
              const {key} = action.payload
                return of({
                  type: success,
                  payload: {
                    count,
                    key,
                    ...normaliseData(results),
                    shifts: normaliseData(shifts).dataObjects
                  }
                })
            }),
            takeUntil(action$.pipe(ofType(cancel))),
            catchError((error) =>
              of({
                type: failure,
                payload: error,
              })
            ))
    })
);

const getEpic = (getterFunc, initial, success, cancel, failure) => (action$) =>
  action$.pipe(
    ofType(initial),
    mergeMap((action) => {
      return getterFunc('GET', action.payload).pipe(
        map((response) => {
          const { count, results } = response.response || {};
          const { key } = action.payload;
          return {
            type: success,
            payload: {
              key,
              count,
              ...normaliseData(results),
            },
          };
        }),
        takeUntil(action$.pipe(ofType(cancel))),
        catchError((error) =>
          of({
            type: failure,
            payload: error,
          })
        )
      );
    })
  );

const getTableBatchData = getBatchEpic(
  getData,
  actions.GET_TABLE_BATCH_DATA_BEGINS,
  actions.GET_TABLE_BATCH_DATA_SUCCESS,
  actions.GET_TABLE_BATCH_DATA_CANCEL,
  actions.GET_TABLE_BATCH_DATA_ERROR,
);

const getTableData = getEpic(
  getData,
  actions.GET_TABLE_DATA_BEGINS,
  actions.GET_TABLE_DATA_SUCCESS,
  actions.GET_TABLE_DATA_CANCEL,
  actions.GET_TABLE_DATA_CANCEL
);

export default combineEpics(getTableData, getTableBatchData);
