import { getRESTApiData } from '../helpers/request/RESTApi';

export const getData = (method, payload) => {
  const { REST, page = 1, hub_id = 1, selectedCategory, selectedTeamLead, selectedCity, selectedTL} = payload;
    if (REST) {
      const {url, params = {}, paramsFromState = {}} = REST;
      // get params from state
      Object.keys(paramsFromState).forEach(key=> {
        if(eval(paramsFromState[key]) !== undefined) params[key] = eval(paramsFromState[key]);
      })
      return getRESTApiData(url, { ...params }, method);
    }
};

export const normaliseData = (data = []) => {
  return data.reduce(
    (acc, ite) => {
      if (ite && ite.id && !acc.data.includes(ite.id)) {
        return {
          data: [...acc.data, ite.id],
          dataObjects: {
            ...acc.dataObjects,
            [ite.id]: ite,
          },
        };
      }
    },
    {
      data: [],
      dataObjects: {},
    }
  );
};
