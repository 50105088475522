import actions from './constants';


class OptionsDispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    
    getOptions(payload) {
        this.dispatch({
        type: actions.GET_OPTIONS_INITIAL,
        payload
        });
    }
    
    getOptionsSuccess(payload) {
        this.dispatch({
        type: actions.GET_OPTIONS_SUCCESS,
        payload,
        });
    }
    
    getOptionsCancel() {
        this.dispatch({
        type: actions.GET_OPTIONS_CANCEL,
        });
    }
    
    getOptionsFailure(payload) {
        this.dispatch({
        type: actions.GET_OPTIONS_FAILURE,
        payload,
        });
    }
}

export default OptionsDispatcher;