import React from 'react';
import { parseData, parseConditional } from './parseData';
import LightTooltip from '../../components/Tooltip/LightTooltip';
import SVGIcon from '../../components/SVGIcon';

export const isNullOrUndefined = (str) => {
  return (
    !str ||
    (typeof str === 'string'
      ? ['null', 'undefined'].includes(str.trim())
      : true)
  );
};

const cancatenateData = ({ data, info }) => {
  const { dataIndex, seperator = info.separator, encloseInParanthesis = false } =
    info || {};
  const cancatenatedData = (dataIndex || []).reduce((acc, ite) => {
    if (acc) return `${acc} ${seperator} ${parseData(data, ite)}`;
    return parseData(data, ite);
  }, '');
  if (isNullOrUndefined(cancatenatedData)) return '';
  return encloseInParanthesis ? `(${cancatenatedData})` : cancatenatedData;
};

const showPartial = ({ str, numOfVisible }) => {
  const words = !Array.isArray(str) ? str.split(',') : str;
  if (words.length <= numOfVisible) return str;
  return (
    <span>
      {words.slice(0, numOfVisible).toString()}{' '}
      <LightTooltip
        arrow
        title={
          <div>
            {words.slice(numOfVisible, words.length).map((word) => (
              <>
                <span>{word}</span>
                <br />
              </>
            ))}
          </div>
        }
        placement="top"
      >
        <span style={{ color: 'blue' }}>
          +{words.length - numOfVisible} more
        </span>
      </LightTooltip>
    </span>
  );
};

const flattenDictKeys = ({ data, info }) => {
  const { dataIndex, condition, numOfVisible, returnType = 'string' } = info;
  const dict = parseData(data, dataIndex);
  let arr;
  if (condition) {
    const { value } = condition;
    arr = Object.keys(dict || {}).reduce((acc, ite) => {
      if (dict[ite] === value) {
        if (acc) return [...acc, ite];
        return ite;
      }
      return acc;
    }, []);
  } else {
    arr = Object.keys(dict || {});
  }
  if (arr && !!numOfVisible && returnType === 'string') {
    return showPartial({ str: arr.toString(), numOfVisible });
  }
  if (returnType === 'array') {
    return arr;
  }
  return arr.toString();
};

const traverseArray = ({ data, info }) => {
  const { dataIndex, condition, numOfVisible, returnType = 'string' } = info;
  const dict = parseData(data, dataIndex);
  let arr = [];
  arr = dict?.length ? dict.map(i => i[condition]) : '';
  if (arr && !!numOfVisible && returnType === 'string') {
    return showPartial({ str: arr, numOfVisible });  
  }
  if (returnType === 'array') {
    return arr;
  }
  return arr.toString();
};

const convertToDict = ({ data, info }) => {
  const { allOptions = [], availKeyValue = true, notAvailKeyValue = false } =
    info || {};
  return allOptions.reduce((acc, ite) => {
    let val = notAvailKeyValue;
    if (data.includes(ite)) val = availKeyValue;
    return {
      ...acc,
      [ite]: val,
    };
  }, {});
};


const formatTypes = ({ data, info }) => {
  const { type } = info;
  switch (type) {
    case 'text':
      if (info.dataIndex && Array.isArray(info.dataIndex)) {
        const possibleNotNullValues = info.dataIndex
          .map((ind) => parseData(data, ind))
          .filter((val) => val);
        return possibleNotNullValues.length > 0 ? possibleNotNullValues[0] : '';
      }
      if(info.conditional) {
        return parseConditional( data, info.conditional )
      }
      return parseData(data, info.dataIndex);
    case 'concatenateData':
      return cancatenateData({ data, info });
    case 'flattenDictKeys':
      return flattenDictKeys({ data, info });
    case 'convertToDict':
      return convertToDict({ data, info });
    case 'traverseArray':
      return traverseArray({ data, info });
    case 'component': 
      if(info.elements) {
         const elements = info.elements.map(element=> {
           const value = formatTypes({data, info:element})
            return {type: element.type, value} 
          })
         return (
           <>
           {elements.map(element=> {
          if(element.type==='text') 
            return (<p>{element.value}&emsp;</p>)
          if(element.type==='icon')
            return (<SVGIcon name={element.value} size="large"/> )
        })}
           </>
           )
      }
      break;
    case 'icon': 
      return parseConditional(data, info.conditional);
    default:
      return parseData(data, info.dataIndex);
  }
};

export const formatOptions = (options, data) => {
  const { type } = options;
  switch (type) {
    case "hubs":
      return data.map(h=>({
        key: h.id,
        displayName: h.short_name
      }));
      case "cities":
        return data.map(h=>({
          key: h.id,
          displayName: h.display_name
        }));
      case "teamLead":
        return data.map(h=>({
          key: h.username,
          label: h.full_name
        }));
      case "category":
        return data.map(h=>({
          key: h.name,
          displayName: h.display_name
        }));
    default:
      return null;
  }
}

export const formatData = ({ data, info }) => {
  if (!info) return null;
  if (Array.isArray(info)) {
    return info.reduce((acc, ite) => {
      return `${!isNullOrUndefined(acc) ? acc : ' '}${formatTypes({
        data,
        info: ite,
      })}`;
    }, '');
  }
  return formatTypes({ data, info });
};
