import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import '../../index.css';
import ShiftForm from './components/ShiftForm';

import TestDriveForm from './components/TestDriveForm';
import WeekOffForm from './components/WeekOffForm';
import { includeSection } from '../../utils';

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    margin: '0',
    overflowY: 'unset',
  },
  container: {
    display: 'flex',
    height: 'auto',
    padding: '12px',
  },
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'black !important',
  },
}));

export default function EditShiftDialog(props) {
  const { open, handleClose, date, data, onChange, dayInfo, sections } = props;
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth = {'md'}
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classes.container}>
        {includeSection(sections, 'shift') && (
          <ShiftForm date={date} shifts={data.shifts || {}} dayInfo={dayInfo} onChange={onChange} />
        )}
        {includeSection(sections, 'testDrive') && (
          <TestDriveForm date={date} dayInfo={dayInfo} onChange={onChange} />
        )}
        {includeSection(sections, 'weekOff') && (
          <WeekOffForm date={date} dayInfo={dayInfo} onChange={onChange} />
        )}
      </div>
    </Dialog>
  );
}

EditShiftDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  dayInfo: PropTypes.object,
  sections: PropTypes.array,
};
