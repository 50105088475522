import { combineEpics, ofType } from 'redux-observable';
import { mergeMap, map, takeUntil, catchError } from 'rxjs/operators';
import { of  } from 'rxjs';
import { getData } from '../../utils/table';
import actionTypes from './constants';

const getEpic = (getterFunc, initial, success, cancel, failure) => (action$) =>
  action$.pipe(
    ofType(initial),
    mergeMap((action) => {
      return getterFunc('GET', action.payload).pipe(
        map((response) => {
          return {
            type: success,
            payload: {...response.response, key: action.payload.key},
          };
        }),
        takeUntil(action$.pipe(ofType(cancel))),
        catchError((error) =>
          of({
            type: failure,
            payload: error,
          })
        )
      );
    })
  );



const getOptionData = getEpic(
  getData,
    actionTypes.GET_OPTIONS_INITIAL,
    actionTypes.GET_OPTIONS_SUCCESS,
    actionTypes.GET_OPTIONS_CANCEL,
    actionTypes.GET_OPTIONS_FAILURE,
);

export default combineEpics(getOptionData);
