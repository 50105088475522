import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import React from 'react';
import PropTypes from 'prop-types';

const ReassignModal=(props)=>{
    const { open, numbers, close } = props;    
    return(
        <Dialog
        open={open}
        onClose={close}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Reassignment Alert</DialogTitle>
        <DialogContent>
            <div>The agent assigned for visits of {numbers.length ? numbers.join(', ') : 'NA' } is on leave for the assigned day. Kindly assign the visits to other agents manually.</div>
        </DialogContent>
        <DialogActions>
            <Button onClick={close} color="primary">
                Got it!
            </Button>
        </DialogActions>
      </Dialog>     
    );
}

ReassignModal.propTypes={
    open: PropTypes.bool.isRequired,
    numbers: PropTypes.array.isRequired,
    close: PropTypes.func.isRequired
}

export default ReassignModal;