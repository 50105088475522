/* eslint-disable no-undef */
const settings = {
  sales: {
    development: {
      baseUrl: 'https://sales-api.dev.ispinnyworks.in',
    },
    production: {
      baseUrl: 'https://sales-api.ispinnyworks.in',
    },
  },
  supply: {
    development: {
      baseUrl: 'https://supply-backend-test.spinny.com',
    },
    production: {
      baseUrl: 'https://supply-backend.spinny.com',
    },
  },
};

const base = () => {
  const REACT_APP_ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV;
  const brokenURL = window.location.pathname.split('/');
  if (brokenURL && brokenURL.length < 3) return null;
  const pod = brokenURL[1];
  if (!Object.keys(settings).includes(pod)) return null;
  return settings[pod][REACT_APP_ENV];
};

export default base;
