import React, { Suspense } from 'react';
import { createObject } from './computation';
import { formElements } from './formElements';
import conditionHelper from '../conditionHelper';
import { parseData } from './parseData';
import { getRESTApiDataFetch } from '../request/RESTApi';
const SVGIcon = React.lazy(() => import('../../components/SVGIcon'));
const ButtonWrapper = React.lazy(() => import('../../components/Button'));

const _generateHelperFunc = ({ funcConfig, refData, functions }) => {
  const { setObject, request, functionToCall } = funcConfig || {};
  let funcConfigHelper;
  if (Array.isArray(funcConfig)) {
    const functionHelpers = funcConfig.map((funcConfigInstance) => {
      return _generateHelperFunc({
        funcConfig: funcConfigInstance,
        functions,
      });
    });
    funcConfigHelper = () => functionHelpers.forEach((func) => func());
  } else if (setObject) {
    let obj;
    if (Array.isArray(setObject)) {
      obj = setObject.reduce((acc, ite) => {
        return {
          ...acc,
          ...createObject(ite, refData),
        };
      }, {});
    } else {
      obj = createObject(setObject, refData);
    }
    funcConfigHelper = () => functions[functionToCall](obj);
  } else if (request) {
    const { url, method, requestBody, onSuccess, onRequest } = request || {};
    const {
      key: { dataIndex: keyDataIndex },
      value: { dataIndex: valueDataIndex, wrapInArray },
    } = requestBody || {};
    const value = parseData(refData, valueDataIndex);
    const body = {
      [parseData(refData, keyDataIndex)]: wrapInArray ? [value] : value,
    };
    funcConfigHelper = () => {
      const onRequestObj = createObject(onRequest.setObject, refData);
      const onRequestFuncConfigHelper = () =>
        functions[onRequest.functionToCall](onRequestObj);
      onRequestFuncConfigHelper();
      getRESTApiDataFetch({ url, method, body })
      .then((response) =>response.json())
      .then(json=>{
        const [firstSuccessObject, ...rest] = onSuccess;
        const { setObject = [] } = firstSuccessObject;
        setObject.push({
            key: "response",
            value: JSON.stringify(json)  
        })
        const updatedFirstSuccessObject = {...firstSuccessObject, setObject}
        const updatedOnSuccess = [updatedFirstSuccessObject, ...rest];
        const onSuccessFuncConfigHelper = _generateHelperFunc({
          funcConfig: updatedOnSuccess,
          functions,
          refData,
        });
        onSuccessFuncConfigHelper();
      });
    };
  } else if (functionToCall) {
    funcConfigHelper = () => functions[functionToCall]();
  }
  return funcConfigHelper;
};

const formatElementTypes = ({ info, refData, functions }) => {
  const { type, element, condition, disabledCondition } = info || {};
  if (!conditionHelper(condition, refData)) {
    return null;
  }
  const { staticText, onClick, icon } = element || {};
  const onClickHelper = _generateHelperFunc({
    funcConfig: onClick,
    refData,
    functions,
  });

  switch (type) {
    case 'button': {
      const shouldBtnBeDisabled = (disabledCondition && conditionHelper(disabledCondition, refData)) || false;

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ButtonWrapper
            name={icon ? 'icon' : 'primary'}
            variant="contained"
            onClick={onClickHelper}
            disabled={shouldBtnBeDisabled}
          >
            {icon ? (
              <Suspense fallback={<div>Loading...</div>}>
                <SVGIcon name={icon.name} />
              </Suspense>
            ) : (
              staticText || 'Button'
            )}
          </ButtonWrapper>
        </Suspense>
      );
    }
    default:
      return formElements({ formElement: info, functions, refData });
  }
};

export const formatElements = ({ info, refData, functions }) => {
  if (info && Array.isArray(info)) {
    return (
      <>
        {info.reduce((acc, ite) => {
          return (
            <>
              {acc}
              {formatElementTypes({ info: ite, refData, functions })}
            </>
          );
        }, null)}
      </>
    );
  }
  return formatElementTypes({ info, refData, functions });
};
