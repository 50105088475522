import React from 'react';

const Spinny = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 8 8">
    <g fill="none" fillRule="evenodd">
        <g fill="#ED244F" fillRule="nonzero">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path d="M9.383 53.254c.341-.339.893-.339 1.234 0l3.129 3.128c.339.341.339.893 0 1.234l-3.13 3.13c-.34.339-.892.339-1.233 0l-3.129-3.13c-.339-.341-.339-.893 0-1.234zm.643 1.658c-.41 0-.752.104-1.026.311-.274.207-.412.502-.412.883 0 .382.11.662.326.84.216.177.558.322 1.025.434.295.072.493.144.593.215.096.064.152.172.149.287.003.114-.05.221-.14.288-.094.07-.223.106-.388.106-.367 0-.767-.189-1.2-.567l.052.536-.582.123c.508.469 1.08.703 1.714.703.441 0 .797-.112 1.067-.336.265-.213.415-.538.405-.878 0-.36-.105-.634-.316-.822-.21-.187-.507-.328-.889-.423-.381-.094-.625-.175-.73-.244-.1-.058-.16-.164-.16-.28-.003-.108.045-.213.13-.281.086-.07.204-.107.353-.107.372 0 .733.133 1.08.398l.565-.121-.11-.535c-.206-.173-.444-.306-.7-.392-.26-.09-.532-.136-.806-.138zm-3.07 1.836c-.138 0-.25.113-.25.251s.112.25.25.251c.067 0 .13-.026.178-.073.047-.048.074-.112.073-.178 0-.139-.112-.25-.25-.25zm6.114 0c-.138.001-.25.113-.25.252 0 .138.113.25.25.25.068 0 .132-.027.18-.074.046-.048.073-.112.072-.179-.001-.138-.114-.25-.252-.249z" transform="translate(-221 -594) translate(64 68) translate(13 415) translate(0 58) translate(138)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default Spinny;