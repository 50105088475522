const actions = {
  GET_TABLE_DATA_BEGINS: 'table/GET_TABLE_DATA_BEGINS',
  GET_TABLE_DATA_SUCCESS: 'table/GET_TABLE_DATA_SUCCESS',
  GET_TABLE_DATA_ERROR: 'table/GET_TABLE_DATA_ERROR',
  GET_TABLE_DATA_CANCEL: 'table/GET_TABLE_DATA_CANCEL',
  
  GET_TABLE_BATCH_DATA_BEGINS: 'table/GET_TABLE_BATCH_DATA_BEGINS',
  GET_TABLE_BATCH_DATA_SUCCESS: 'table/GET_TABLE_BATCH_DATA_SUCCESS',
  GET_TABLE_BATCH_DATA_ERROR: 'table/GET_TABLE_BATCH_DATA_ERROR',
  GET_TABLE_BATCH_DATA_CANCEL: 'table/GET_TABLE_BATCH_DATA_CANCEL',

  SET_INFORMATION: 'table/SET_INFORMATION',
  SET_ROW_EDIT_INFORMATION: 'table/SET_ROW_EDIT_INFORMATION',
  SET_ROW_EXPANDED_INFORMATION: 'table/SET_ROW_EXPANDED_INFORMATION',
  SET_INFORMATION_SUCCESS: 'table/SET_INFORMATION_SUCCESS',
  CLOSE_MODAL: 'table/CLOSE_MODAL'
};

export default actions;
