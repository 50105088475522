import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import VirtualizedScrollSyncGridWrapper from '../../components/VirtualizedScrollSyncGridWrapper';
import TableDispatcher from '../../redux/table/actions';
import ReassignDialog from '../../components/ReassignDialog';
import Loading from '../../components/Loading';

const Table = (props) => {
  const { getData, elements, elementKey } = props;

  const { tableState, selectedHub, selectedcategory, selectedCity, selectedTL } = useSelector((state) => ({
    tableState: state.table[elementKey],
    selectedHub: state.table.selectedHub,
    selectedcategory: state?.table?.selectedCategory || state?.options?.category?.[0]?.name,
    selectedCity: state.table?.selectedCity,
    selectedTL: state.table?.selectedTL,
  }));

  const { openReassignModal, reassignedContactNumbers= [] } = useSelector((state) => ({
    openReassignModal: state.table.openReassignModal,
    reassignedContactNumbers: state.table.reassignedContactNumbers
  }));

  const dispatch = useDispatch();
  const tableDispatcher = new TableDispatcher(dispatch);

  const {
    data = [],
    dataObjects = {},
    shifts = {},
    count,
    editableRow,
    page = 1,
    expandedRow,
    expandedRowState,
  } = tableState || {};
  
  const tableData = data.map((key) => ({...dataObjects[key], shifts}));

  const requestDataOnLoad = () => {
    if(!getData?.onLoad){
      tableDispatcher.getTableData({
        key: elementKey,
        ...getData,
        page,
        hub_id: selectedHub,
        selectedCategory: selectedcategory,
        selectedCity:selectedCity,
        selectedTL:selectedTL,
        ops: Object.keys(shifts).length ? 'afterload' : ''
      });
    }
  };

  const requestDataPagination = () => {
    tableDispatcher.getTableData({
      key: elementKey,
      ...getData,
      page,
      hub_id: selectedHub,
      selectedCategory: selectedcategory,
      selectedCity:selectedCity,
      selectedTL:selectedTL,
      ops: Object.keys(shifts).length ? 'afterload' : ''
    });
  };

  const closeModal = () => {
    tableDispatcher.closeModal(0)
  }

  useEffect(() => {
    requestDataPagination(page);
  }, [page]);
  return (
    <>
    <VirtualizedScrollSyncGridWrapper
      {...elements}
      tableData={tableData}
      requestData={requestDataOnLoad}
      totalCount={count}
      setInformation={(payload) =>
        tableDispatcher.setInformation({
          key: elementKey,
          ...payload,
        })
      }
      setInformationSuccess={(payload) =>
        tableDispatcher.setInformationSuccess({
          key: elementKey,
          ...payload,
        })
      }
      setRowEditInformation={({ information, rowKey }) =>
        tableDispatcher.setRowEditInformation({
          key: elementKey,
          rowKey,
          information,
        })
      }
      setRowExpandedInformation={({ information, rowKey }) =>
        tableDispatcher.setRowExpandedInformation({
          key: elementKey,
          rowKey,
          information,
        })
      }
      setRowInformation={({ information, rowKey }) =>
        tableDispatcher.setRowEditInformation({
          key: elementKey,
          rowKey,
          information,
        })
      }
      editableRow={editableRow}
      dataObjects={dataObjects}
      shifts={shifts}
      expandedRow={expandedRow}
      expandedRowState={expandedRowState}
    />
    {
      openReassignModal ?
      <Suspense fallback={<Loading isDisableBackdropClick />}>
        <ReassignDialog
          open={openReassignModal}
          numbers={reassignedContactNumbers} 
          close={closeModal}
        />
      </Suspense>
    :''}
    </>
  );
};

Table.propTypes = {
  elementKey: PropTypes.string.isRequired,
  getData: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
};
export default Table;
