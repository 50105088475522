import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import SpinnyIcon from '../SVGIcon/icons/shapes/SpinnyIcon';
import HomeIcon from '../SVGIcon/icons/shapes/Home';
import './index.css';

import EditShiftDialog from './components/EditDialog';
import { includeSection } from './utils';
import calendar from '../../constants/dataset/calendar';


const ShiftDay = (props) => {
  const [openEditDialog, setOpenDialog] = useState(false);

  const handleEditDialog = () => {
    setOpenDialog(!openEditDialog);
  };

  const { dateKey, dayInfo, onChange, data, sections } = props;
  const [dayInfoState, updateDayInfoState] = useState(dayInfo);

  const date = moment(new Date(dateKey));
  const showDate = moment(date).format('DD');
  const showDay = moment(date).format('ddd');
  const shift = dayInfoState.week_off
    ? includeSection(sections, 'weekOff')
      ? 'W.Off'
      : '--'
    : includeSection(sections, 'shift')
    ? calendar.shifts[data['shifts'][dayInfoState.shift].display_name.split(' ')[0]] || data['shifts'][dayInfoState.shift].display_name.split(' ')[0]
    : '--';
  return (
    <>
      <EditShiftDialog
        open={openEditDialog}
        handleClose={handleEditDialog}
        date={moment(date).format('D MMM')}
        onChange={(data) => {
          updateDayInfoState((prevState) => ({
            ...prevState,
            ...data,
          }));
          onChange(
            {
              ...dayInfoState,
              ...data,
            },
            date
          );
        }}
        dayInfo={dayInfoState}
        data={data}
        sections={sections}
      />
      <div className="day_card" onClick={handleEditDialog}>
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{showDate}</div>
        <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{showDay}</div>
        <div style={{ color: shift === 'W.Off' ? '#039BE5' : '#000' }}>
          {shift}
        </div>
        {includeSection(sections, 'testDrive') && (
          <div>{dayInfoState.htd ? <HomeIcon /> : <SpinnyIcon />}</div>
        )}
      </div>
    </>
  );
};

ShiftDay.propTypes = {
  dateKey: PropTypes.string.isRequired,
  dayInfo: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  data: PropTypes.object.isRequired,
  sections: PropTypes.array,
};

export default ShiftDay;
