import { parseData } from './parseData';

const computeObject = (keyValueInfo, refData = {}) => {
  const { key, value } = keyValueInfo;
  const computedValue =
    value && typeof value === 'object'
      ? parseData(refData, value.dynamic)
      : value;
  return {
    [key]: computedValue,
  };
};

export const createObject = (info, refData) => {
  return computeObject(info, refData);
};
