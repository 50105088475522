import actions from './constants';

const initialState = {
    hubs : [],
};

export default (state = initialState, action) => {
  const { key } = action.payload || {};
  switch (action.type) {
    case actions.GET_OPTIONS_INITIAL:
      return {
          ...state,
          loading: true,
          data: [],
          dataObjects: {},
        }

    case actions.GET_OPTIONS_SUCCESS:
      return {
          ...state,
          loading: false,
          [key]:action.payload.results || action.payload[action.payload.key],
        }
        
    case actions.GET_OPTIONS_FAILURE:
      return {
          ...state,
          loading: false,
          [key]: []
        }
        default:
            return state;
    }
};