import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  RadioGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import StyledRadio from '../../../../../StyledRadio';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'black !important',
  },
  label: {
    fontSize: '14px',
  },
  weekOff: {
    padding: '0 12px',
  },
}));

const WeekOffForm = ({ dayInfo, onChange, date }) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.weekOff}>
      <FormLabel classes={{ root: classes.root }} component="legend">
        Week Off on {date}
      </FormLabel>
      <RadioGroup
        aria-label="weekOff"
        name="weekOff"
        value={dayInfo.week_off}
        onChange={(e) => {
          onChange({ week_off: e.target.value === 'true' });
        }}
        onClick={() => {
          if (dayInfo.week_off) {
            onChange({ week_off: false });
          }
        }}
      >
        <FormControlLabel
          classes={{ label: classes.label }}
          value={true}
          control={<StyledRadio />}
          label="Week Off"
        />
      </RadioGroup>
    </FormControl>
  );
};

WeekOffForm.propTypes = {
  classes: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  dayInfo: PropTypes.object,
};

export default WeekOffForm;
