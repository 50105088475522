import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import OptionsDispatcher from '../../redux/options/actions';
import TableDispatcher from '../../redux/table/actions';
import { formatData, formatOptions } from '../../helpers/formatters/formatData';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { debounce } from 'lodash';
export default (props) => {
  const {
    element: {
      label = 'Select',
      customOptions,
      formatValue,
      onSelect,
      getData,
      changeData,
      optionsType = "",
    },
    elementKey,
  } = props || {};

  let {
    // eslint-disable-next-line react/prop-types
    functions
  } = props;
  const [options, updateOptions] = useState([]);
  const dispatch = useDispatch();
  const optionsDispatcher = new OptionsDispatcher(dispatch);
  const tableDispatcher = new TableDispatcher(dispatch);

  const { optionsFetched= [], selectedCategory, selectedCity  } = useSelector((state) => ({
    optionsFetched: state.options[elementKey],
    selectedCategory: state.table.selectedCategory,
    selectedCity: state.table?.selectedCity,
  }), [shallowEqual]);

  const functionsDeclared = {
      onSelectTL: (val)=>{
        tableDispatcher.getTableData({
              ...changeData,
              key: 'insideSalesAdmin',
          selectedTL: val?.teamLead?.key || '',
          selectedCategory: selectedCategory,
          selectedCity: selectedCity
          })
      },
  }

  functions = {...functions, ...functionsDeclared}

  useEffect(()=>{
    if (optionsFetched.length && optionsType === "fetch") {
      updateOptions(formatOptions(customOptions,optionsFetched));
    }
  },[optionsFetched]);

  const _handleChange = (value) => {
    functions[onSelect.functionToCall]({
      [elementKey]: formatValue
        ? formatData({
            data: value,
            info: {
              ...formatValue,
              allOptions: options.map((option) => option.key),
            },
          })
        : value,
    });
  };

  const _handleInputChange = debounce((value) => {
    if(value){
      getData.REST.url = getData.REST.baseUrl + `&search=${value}`
      optionsDispatcher.getOptions({
        key: elementKey,
        ...getData,
      });
    }
  },500);

  return (
    <Autocomplete
      options={options}
      style={{ width: 150 }}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(e, newValue) => _handleChange(newValue)}
      onInputChange={(e, newValue) => _handleInputChange(newValue)}
    />
  );
};
