import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  RadioGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import StyledRadio from '../../../../../StyledRadio';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'black !important',
  },
  label: {
    fontSize: '14px',
  },
  testdrives: {
    borderRight: '1px solid black',
    marginRight: '8px',
    padding: '0 12px',
  },
}));

const TestDriveForm = ({ date, dayInfo, onChange }) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.testdrives}>
      <FormLabel
        classes={{ root: classes.root }}
        component="legend"
      >{`Test Drive type on ${date}`}</FormLabel>
      <RadioGroup
        aria-label="testdrives"
        name="testdrives"
        value={dayInfo.htd}
        onChange={(e) => onChange({ htd: e.target.value === 'true' })}
      >
        <FormControlLabel
          style={{ color: '#975AC1' }}
          classes={{ label: classes.label }}
          value={true}
          control={<StyledRadio />}
          label="Home Test Drives"
        />
        <FormControlLabel
          style={{ color: '#FB215A' }}
          classes={{ label: classes.label }}
          value={false}
          control={<StyledRadio />}
          label="Showroom Test Drive"
        />
      </RadioGroup>
    </FormControl>
  );
};

TestDriveForm.propTypes = {
  classes: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  dayInfo: PropTypes.object,
};

export default TestDriveForm;
