import React from 'react';
import StateContext from '../../context/StateContext';
import './Button.css';

export default ({ title, style, action }) => {
  const stateContext = React.useContext(StateContext);

  const _onClick = () => {
    if (!action) return;
    Object.keys(action || {}).forEach((typeOfAction) => {
      if (typeOfAction === 'globalStateUpdate') {
        const { staticValue } = action[typeOfAction];
        if (staticValue) {
          stateContext.updateGlobalState(staticValue);
        }
      }
    });
  };

  return (
    <button className="button" style={style} onClick={_onClick}>
      {title}
    </button>
  );
};
