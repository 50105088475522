export default (condition, data) => {
  // const stateContext = React.useContext(StateContext);
  // const { globalState } = stateContext || {};

  if (!condition) return true;
  try {
    const result = eval(`${condition}`);
    return result;
  } catch (e) {
    return false;
  }
};
