import React from 'react';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  circularProgress: {
    position: 'fixed',
    top: 'calc(50% - 45px)',
    left: 'calc(50% - 45px)',
    zIndex: 1000
  },
  disableBackdropClick: {
    background: 'rgba(0, 0, 0, 0.27)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    zIndex: 9999
  },
  blur: {
    backdropFilter: 'blur(3px)',
    background: 'transparent',
    zIndex: 'auto'
  },
  absolute: {
    position: 'absolute'
  }
};

function Loading(props) {
  const { classes, isDisableBackdropClick, isBlur } = props;
  // isBlur props centers the loader and blur the background inside parent view, given that parent has relative property set
  if (isBlur) {
    return (
      <div className={clsx(classes.disableBackdropClick, classes.blur, classes.absolute)}>
        <CircularProgress
          className={clsx(classes.circularProgress, classes.absolute)}
          size={90}
          thickness={1}
        />
      </div>
    );
  }
  if (isDisableBackdropClick) {
    return (
      <div className={classes.disableBackdropClick}>
        <CircularProgress
          className={classes.circularProgress}
          size={90}
          thickness={1}
          style={{ color: '#fff' }}
        />
      </div>
    );
  }
  return (
    <CircularProgress
      className={classes.circularProgress}
      size={90}
      thickness={1}
      color="secondary"
    />);
}

Loading.defaultProps = {
  isDisableBackdropClick: false,
  isBlur: false
};

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  isBlur: PropTypes.bool,
  isDisableBackdropClick: PropTypes.bool
};
export default (withStyles(styles)(Loading));
