import actions from './constants';

class TableDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  getTableData(payload) {
    // afterload is set once onload operation is done for a batch scenario, e.g pagination, update
    // batch type represents scenarios where multiple api calls is required parallely
    if(payload.type && payload.type==='batch' && payload.ops==="afterload" ) {
      const info = payload.REST.find(load => load.type==="default")      
      this.dispatch({
        type: actions.GET_TABLE_DATA_BEGINS,
        payload: {...payload, REST: info},
      });
    }
    else {
      this.dispatch({
        type: payload.type && payload.type==='batch' ? actions.GET_TABLE_BATCH_DATA_BEGINS: actions.GET_TABLE_DATA_BEGINS,
        payload,
      });
    }
  }

  setInformation(payload) {
    this.dispatch({
      type: actions.SET_INFORMATION,
      payload,
    });
  }

  setRowEditInformation(payload) {
    this.dispatch({
      type: actions.SET_ROW_EDIT_INFORMATION,
      payload,
    });
  }

  setRowExpandedInformation(payload) {
    this.dispatch({
      type: actions.SET_ROW_EXPANDED_INFORMATION,
      payload,
    });
  }

  setInformationSuccess(payload) {
    this.dispatch({
      type: actions.SET_INFORMATION_SUCCESS,
      payload,
    });
  }

  closeModal(payload) {
    this.dispatch({
      type: actions.CLOSE_MODAL,
      payload,
    });
  }
}

export default TableDispatcher;
