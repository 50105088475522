import React, { Suspense } from 'react';
const Select = React.lazy(() => import('../../components/Select'));

const formElementTypes = ({ formElement, functions, refData }) => {
  const { type } = formElement || {};
  switch (type) {
    case 'select':
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Select {...formElement} functions={functions} refData={refData} />
        </Suspense>
      );
    default:
      return 'N/A';
  }
};

export const formElements = ({ formElement, functions, refData }) => {
  return formElementTypes({ formElement, functions, refData });
};
