import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShiftDay from './shiftDay';
import { getRESTApiDataFetch } from '../../helpers/request/RESTApi';
import './index.css';

const transform = {
  htd: 'is_htd',
  week_off: 'is_week_off',
};

const formattedEditState = (editState) => {
  return Object.keys(editState).reduce((acc, ite) => {
    if (Object.keys(transform).includes(ite)) {
      return {
        ...acc,
        [transform[ite]]: String(editState[ite]),
      };
    }
    return {
      ...acc,
      [ite]: editState[ite],
    };
  }, {});
};

const Calendar = (props) => {
  const { data, getData, onChange, sections } = props;

  const [calendarData, setCalendarData] = useState({});

  const onChangeHelper = (editData, date) => {
    let intermediateState =
      data && data.expandedState ? data.expandedState : [];
    const formattedDate = date.format('YYYY-MM-DD');

    let objPresent = false;
    intermediateState = intermediateState.map((obj) => {
      if (formattedDate === obj.date) {
        objPresent = true;
        return {
          date: formattedDate,
          ...formattedEditState(editData),
        };
      }
      return obj;
    });
    if (!objPresent) {
      intermediateState = [
        ...intermediateState,
        {
          date: formattedDate,
          ...formattedEditState(editData),
        },
      ];
    }
    onChange(intermediateState);
  };

  const url = `${getData.REST.url}${data.id}/`;
  useEffect(() => {
    getRESTApiDataFetch({ url, method: 'GET' })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data === 'string') {
          return setCalendarData(`HTD Schedule Does Not Exist For This User`);
        }
        return setCalendarData(data);
      });
  }, []);

  useEffect(() => {});
  return (
    <div className="calendar">
      {typeof calendarData !== 'string' ? (
        Object.keys(calendarData).map((date, i) => (
          <ShiftDay
            key={i}
            dateKey={date}
            dayInfo={calendarData[date]}
            onChange={onChangeHelper}
            data={data}
            sections={sections}
          />
        ))
      ) : (
        <span style={{ padding: '16px 0' }}>{calendarData}</span>
      )}
    </div>
  );
};

Calendar.propTypes = {
  data: PropTypes.object.isRequired,
  getData: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  sections: PropTypes.array,
};

export default Calendar;
