/**
 * This is a temporary login page.
 * In future, it will be replaced with JSON config!
 */

import React, { useState } from 'react';
import { Button, FormControl, Input, InputLabel } from '@material-ui/core';

import styles from './Login.module.scss';
import './FormStyles.scss';

export default () => {
  const [fieldValues, updateFieldValues] = useState({});

  const handleFieldChange = (event) => {
    updateFieldValues((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__wrapper}>
        <form
          className={styles.container__wrapper__form}
          onSubmit={handleFormSubmit}
        >
          <FormControl>
            <InputLabel htmlFor="username"> Username </InputLabel>
            <Input id="username" onChange={handleFieldChange} />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="password"> Password </InputLabel>
            <Input id="password" type="password" onChange={handleFieldChange} />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            className={styles.container__wrapper__form__button}
          >
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};
