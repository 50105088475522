/**
 * sample redux state for table
 * {
 *  [table-1]: {
 *  data: [row-1-key, row-2-key, ....],
 *  dataObjects: {
 *      [row-1-key]: {
 *          param-1: value-1,
 *          param-2: value-2
 *      }
 *  },
 * [table-2]: {
 *      ...
 *  }
 * }
 */

import actions from './constants';

const initialState = {
  openReassignModal: 0,
  reassignedContactNumbers: []
};

export default (state = initialState, action) => {
  const { key: tableKey } = action.payload || {};
  switch (action.type) {
    case actions.GET_TABLE_DATA_BEGINS:
    case actions.GET_TABLE_BATCH_DATA_BEGINS:
      return {
        ...state,
        [tableKey]: {
          ...state[tableKey],
          loading: true,
          data: [],
          dataObjects: {},
        },
        selectedHub: action.payload?.hub_id || 1,
        selectedCategory: action.payload.selectedCategory,
        selectedCity: action.payload?.selectedCity,
        selectedTL: action.payload?.selectedTL,

      };
    case actions.GET_TABLE_DATA_SUCCESS:
      return {
        ...state,
        [tableKey]: {
          ...state[tableKey],
          loading: false,
          ...action.payload,
        },
      };

    case actions.GET_TABLE_BATCH_DATA_SUCCESS:
      return {
        ...state,
        [tableKey]: {
          ...state[tableKey],
          loading: false,
          ...action.payload,
        },
      };
        
    case actions.GET_TABLE_DATA_ERROR:
    case actions.GET_TABLE_BATCH_DATA_ERROR:
      return {
        ...state,
        [tableKey]: {
          ...state[tableKey],
          loading: false,
        },
      };
    case actions.SET_INFORMATION:
      return {
        ...state,
        [tableKey]: {
          ...state[tableKey],
          ...action.payload,
        },
      }
    case actions.SET_INFORMATION_SUCCESS:
      {
        const response = JSON?.parse(action.payload.response)?.[0];
        return {
          ...state,
          ...(!!response?.reassign_contact_numbers?.length && 
            { 
              openReassignModal: 1,
              reassignedContactNumbers: response?.reassign_contact_numbers
            }),
          [tableKey]: {
            ...state[tableKey],
            ...action.payload
          },
        }
    }
    case actions.SET_ROW_EDIT_INFORMATION:
      return {
        ...state,
        [tableKey]: {
          ...state[tableKey],
          dataObjects: {
            ...state[tableKey].dataObjects,
            [action.payload.rowKey]: {
              ...state[tableKey].dataObjects[String(action.payload.rowKey)],
              editState: {
                ...state[tableKey].dataObjects[String(action.payload.rowKey)]
                  .editState,
                ...action.payload.information,
              },
            },
          },
        },
      };
    case actions.SET_ROW_EXPANDED_INFORMATION:
      return {
        ...state,
        [tableKey]: {
          ...state[tableKey],
          dataObjects: {
            ...state[tableKey].dataObjects,
            [action.payload.rowKey]: {
              ...state[tableKey].dataObjects[String(action.payload.rowKey)],
              expandedState: action.payload.information,
            },
          },
        },
      };
    case actions.CLOSE_MODAL:
      return {
        ...state,
        openReassignModal: 0,
        reassignedContactNumbers: []
      }
    default:
      return state;
  }
};
