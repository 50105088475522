import { combineReducers } from 'redux';
import users from './users/reducers';
import tableReducers from './table/reducers';
import optionsReducers from './options/reducers';

const reducers = combineReducers({
  users,
  table: tableReducers,
  options: optionsReducers,
});

export default reducers;
