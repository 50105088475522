import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

const CorrectIcon = React.lazy(() => import('./icons/action/Correct'));
const CancelIcon = React.lazy(() => import('./icons/action/Cancel'));
const EditIcon = React.lazy(() => import('./icons/action/Edit'));
const CalendarToggledOff = React.lazy(() =>
  import('./icons/action/CalendarToggledOff')
);
const CalendarToggledOn = React.lazy(() =>
  import('./icons/action/CalendarToggledOn')
);
const SpinnyIcon = React.lazy(() => import('./icons/shapes/SpinnyIcon'));
const HomeIcon = React.lazy(() => import('./icons/shapes/Home'));

const SVGIcon = (props) => {
  const { name } = props;

  switch (name) {
    case 'correct':
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CorrectIcon {...props} />
        </Suspense>
      );
    case 'cancel':
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CancelIcon {...props} />
        </Suspense>
      );
    case 'calendarToggledOff':
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CalendarToggledOff {...props} />
        </Suspense>
      );
    case 'calendarToggledOn':
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CalendarToggledOn {...props} />
        </Suspense>
      );
    case 'edit':
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <EditIcon {...props} />
        </Suspense>
      );
    case 'spinny': 
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <SpinnyIcon {...props}/>
        </Suspense>
    )
    case 'home': 
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <HomeIcon {...props}/>
        </Suspense>
    )
    default:
      return null;
  }
};

SVGIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SVGIcon;
