import { URL } from './API';
import { ajax } from 'rxjs/ajax';


const getQueryString = (params) => {
  const queryString = Object.keys(params).reduce((acc, ite) => {
    if (acc) return `${acc}&${ite}=${params[ite]}`;
    return `?${ite}=${params[ite]}`;
  }, '');
  return queryString;
}

export const getRESTApiData = (url, params) => {
  const queryString = getQueryString(params);
  return ajax({
    url: `${URL}/${url}${queryString}`,
    withCredentials: true,
    body: params,
  });
};

 export const getRESTApiDataFetch = ({ url, method, body }) => {
  return fetch(`${URL}/${url}`, {
    method,
    body: JSON.stringify(body),
    credentials: 'include',
  });
};
