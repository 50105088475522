import { weekDays } from '../../constants/dataset/calendar';
import { formatData } from '../../helpers/formatters/formatData';

export const generateDataset = (options, data) => {
  const { type } = options;
  switch (type) {
    case 'weekDays':
      return weekDays.map((weekDay) => ({
        key: weekDay.toLowerCase(),
        displayName: weekDay,
      }));
    case "shiftTimings": 
     // eslint-disable-next-line no-case-declarations
     const { dataset, key, customFormat } = options;
     return Object.values(data[dataset]).map(shift=> {
      return {
        key: eval(key),
        displayName: formatData({data: shift, info: customFormat})
      }
     });
    default:
      return [];
  }
};
