import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import rootEpic from './rootEpic';

const epicMiddleware = createEpicMiddleware();

export default function configureStore() {

  const middlewares = [epicMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  // const composeEnhancers = process.env.NODE_ENV !== 'production'
  //   && typeof window === 'object'
  //   && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  //     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  //         // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
  //         // Prevent recomputing reducers for `replaceReducer`
  //         shouldHotReload: false,
  //       })
  //     : compose;
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable */
  const store = createStore(
    rootReducer,
    composeEnhancers(...enhancers),
  );


  // const store = createStore(
  //   rootReducer,
  //   composeWithDevTools(
  //     applyMiddleware(epicMiddleware)
  //   )
  // );

  epicMiddleware.run(rootEpic);

  return store;
}