import React from 'react';
import PropTypes from 'prop-types';
import DynamicGrid from '../components/DynamicGrid';
import ButtonWrapper from '../components/ButtonWrapper';
import ShiftCalendar from '../components/ShiftCalendar';
import conditionHelper from './conditionHelper';
import Table from '../containers/Table';
import Select from '../components/Select';
import AutoSearchSelect from '../components/AutoSearchSelect';

const componentHelper = ({ component }) => {
  if (!component || !component.type) return null;
  const { condition } = component;
  if (!conditionHelper(condition)) return null;
  switch (component.type) {
    case 'dynamicGrid':
      return <DynamicGrid {...component} />;
    case 'button':
      return <ButtonWrapper {...component} />;
    case 'table':
      return <Table {...component} />;
    case 'tab':
      return <h1>Tabs</h1>;
    case 'ShiftCalendar':
      return <ShiftCalendar {...component} />
    case 'select':
      return <Select {...component} />;
    case 'AutoSearchSelect':
      return <AutoSearchSelect {...component}  />
    default:
      return null;
  }
};

componentHelper.propTypes = {
  component: PropTypes.object.isRequired,
}

export default componentHelper;
